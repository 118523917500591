import Rollbar from 'rollbar'
import Vue from 'vue'

const config = {
  accessToken: '19989081c1184d889fbb4ae4773cce81',
  captureUncaught: true,
  captureUnhandledRejections: true,
  enabled: true,
  environment: 'production',
}

const rollbar = new Rollbar(config)

Vue.config.errorHandler = (err, vm, info) => {
  console.error(err)
  rollbar.error(err, { info })
}

window.onerror = function (message, source, lineno, colno, error) {
  console.error('Global Error:', message, error)
  rollbar.error(error || message, { source, lineno, colno })
}

window.addEventListener('unhandledrejection', event => {
  console.error('Unhandled Promise Rejection:', event.reason)
  rollbar.error(event.reason)
})
