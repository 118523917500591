import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import lodash from 'lodash'
import Vue from 'vue'
import { VueMaskDirective } from 'v-mask'
import App from './App.vue'
import './plugins/axios'
import './plugins/sanitizer'
import './plugins/rollbar'
import vuetify from './plugins/vuetify'
import i18n from './common/i18n'
import router from './router'
import store from './store'

import { registerModules } from './register-modules'

import authModule from './modules/auth'
import articleModule from './modules/article'
import embedModule from './modules/embed'
import chartModule from './modules/chart'
import userModule from './modules/user'
import companyModule from './modules/company'
import portfolioModule from './modules/portfolio'
import settingModule from './modules/setting'
import planModule from './modules/plan'

registerModules({
  auth: authModule,
  embed: embedModule,
  article: articleModule,
  chart: chartModule,
  user: userModule,
  company: companyModule,
  portfolio: portfolioModule,
  setting: settingModule,
  plan: planModule,
})

Vue.directive('mask', VueMaskDirective)

Vue.prototype.$_ = lodash
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
